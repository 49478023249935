import {
    InformationCircleIcon, VolumeUpIcon, VolumeOffIcon
  } from '@heroicons/react/outline'
  import { useState } from 'react'
  import { InfoModal } from '../../components/modals/InfoModal'
  import {
    GAME_TITLE,
  } from '../../constants/strings'

  type Props = {
    points: number,
    audioOn: boolean,
    toggleAudio: () => void,
  }
  
  export const Banner = ({ points, audioOn, toggleAudio } : Props) =>{
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  
    document.documentElement.classList.add('dark')
  
    return (
      <>
        <div className="flex w-90 mx-auto items-center">
          <h1 className="text-3xl ml-2.5 font-bold dark:text-white">
            {GAME_TITLE}
          </h1>
          <h1 className="text-xl ml-2.5 grow text-center font-bold dark:text-white">
            {points}
          </h1>
          {audioOn ? 
            <VolumeUpIcon
              className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
              onClick={toggleAudio}
            /> :
            <VolumeOffIcon
              className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
              onClick={toggleAudio}
            />
          }
          <InformationCircleIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
      </>
    )
  }
  