import { useState } from 'react'
import { Grid } from './components/grid/Grid'
import { BlockPreview } from './components/grid/BlockPreview'
import { Piece, Location, DEFAULT_PIECE } from './lib/pieces'

type Props = {
    owned: Location[],
    dead: Location[],
    pieceIndex: number,
    pieceOrder: Piece[],
    nextDeadCell: Location,
    place: (piece: Piece) => void,
    increment: () => void,
}

export const Game = ({ owned, dead, pieceIndex, pieceOrder, nextDeadCell, place, increment }: Props) => {

  const [loaded, setLoaded] = useState(false)
  const [selectedPiece, setSelectedPiece] = useState(DEFAULT_PIECE)
  const [nextPiece, setNextPiece] = useState(pieceOrder[pieceIndex])

  if (!loaded && pieceIndex === 0) {
    setSelectedPiece(DEFAULT_PIECE)
    setNextPiece(pieceOrder[pieceIndex])
    setLoaded(true)
  }

  const handleHover = (location: Location) => {
    setSelectedPiece({
      ...selectedPiece,
      origin: location
    })
  }

  const handlePlace = (location: Location) => {
    place({
        ...selectedPiece,
        origin: location
    })

    setSelectedPiece(nextPiece)
    setNextPiece(pieceOrder[pieceIndex + 1])
    increment()
    setLoaded(false)
  } 

  return (
    <>
      <Grid piece={selectedPiece} dead={dead} owned={owned} nextDeadCell={nextDeadCell} place={handlePlace} hover={handleHover} />
      <BlockPreview piece={selectedPiece} nextPiece={nextPiece} />
    </>
  )
}
