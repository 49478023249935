import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const OWNED_BLUE = { status: "Owned", color: 'blue', dying: false }
const OWNED_GREEN = { status: "Owned", color: 'green', dying: false }

const DEAD_GREEN = { status: "Dead", color: 'green', dying: false }
const DYING_GREEN = { status: "Dead", color: 'green', dying: true }

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300 mb-2">
        The Goal is to accumulate a high score. Each day is a unique challenge, consistent for every player. 
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Place blocks on the grid and try to form 2x2 Blocks.
      </p>

      <div className="mx-4 scale-75">
          <div className="flex justify-center mb-1">
              <Cell column={0} key={3} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
              <Cell column={1} key={4} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
          </div>
          <div className="flex justify-center mb-1">
              <Cell column={0} key={6} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
              <Cell column={1} key={7} status={OWNED_GREEN} hover={() => null} onClick={() => null} />
          </div>
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The above block would be worth 4 points.
      </p>

      <div className="mx-4 scale-75">
          <div className="flex justify-center mb-1">
              <Cell column={0} key={3} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
              <Cell column={1} key={4} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
          </div>
          <div className="flex justify-center mb-1">
              <Cell column={0} key={6} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
              <Cell column={1} key={7} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
          </div>
      </div>

      <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
        The above block of a solid colour is worth 8 points. Creating multiple non overlapping blocks will multiply the score by the multiple. 
      </p>

      <div className="mx-4 scale-75">
          <div className="flex justify-center mb-1">
              <Cell column={0} key={3} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
              <Cell column={1} key={4} status={DEAD_GREEN} hover={() => null} onClick={() => null} />
          </div>
          <div className="flex justify-center mb-1">
              <Cell column={0} key={6} status={DYING_GREEN} hover={() => null} onClick={() => null} />
              <Cell column={1} key={7} status={OWNED_BLUE} hover={() => null} onClick={() => null} />
          </div>
      </div>

      <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
        Dead Grey cells are placed every 4 seconds and are highlighted by the red border. Once a cell dies it can no longer be used.
      </p>

      <p className="text-sm text-gray-500 dark:text-gray-300 mb-2">
        Speed is key but strategic placement can result in some big point totals. Have Fun Place Blocks.
      </p>
    </BaseModal>
  )
}
