import { GRID_SIZE } from "../constants/settings";
import { getSeedOfTheDay } from "./seeds"
var seedrandom = require('seedrandom');

export type Piece = {
    id: number,
    color: string,
    origin: Location,
    dimensions: any[]
}

export type Location = {
    x: number,
    y: number,
    color: string
}

export const DEFAULT_PIECE = {id: 0, origin:{x: -1, y: -1, color: 'None'}, color: "blue", dimensions: [{x: 0, y: 0}]}

const COLORS = [
    'blue',
    'orange',
    'green',
    'yellow',
    'purple'
]

const PIECES = [
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}]},
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}, {x: 0, y: 1}]},
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}, {x: 1, y: 0}]},
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}, {x: 0, y: 1}, {x: 1, y: 0}]},
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}, {x: 0, y: 1}, {x: -1, y: 0}]},
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}, {x: 1, y: 0}, {x: 0, y: -1}]},
    { ...DEFAULT_PIECE, dimensions: [{x: 0, y: 0}, {x: -1, y: 0}, {x: 0, y: -1}]},
]

let rng = new seedrandom(getSeedOfTheDay())

export const generatePieceOrder = (): Piece[] => {
    let pieces : Piece[] = []
    rng = new seedrandom(getSeedOfTheDay())

    for (let i = 0; i < 250; i++) {
        pieces.push(createRandomPiece())
    }

    return pieces
}

export const createRandomPiece = (): Piece => {
    return {
        ...PIECES[Math.floor(rng() * PIECES.length)],
        color: COLORS[Math.floor(rng() * COLORS.length)]
    }
}

export const generateDeadCellOrder = (): Location[] => {
    let cells: Location[] = []

    for (let i = 0; i < GRID_SIZE; i++) {
        for (let j = 0; j < GRID_SIZE; j++) {
            cells.push({ x: j, y: i, color: 'None'})
        }
    }

    rng = new seedrandom(getSeedOfTheDay())
    return shuffleDeadCells(cells)
}

const shuffleDeadCells = (cells: Location[]): Location[] => {
    let currentIndex = cells.length, randomIndex;

    while (currentIndex !== 0) {
        randomIndex = Math.floor(rng() * currentIndex)
        currentIndex--

        [cells[currentIndex], cells[randomIndex]] = [
            cells[randomIndex], cells[currentIndex]];
    }

    return cells
}

export const getCompletedBlocks = (owned: Location[]) => {
    let completedBlocks: any[] = []

    for (let i = 0; i < GRID_SIZE - 1; i++) {
        for (let j = 0; j < GRID_SIZE - 1; j++) {
            if (isCompletedBlock(j, i, owned)) {
                completedBlocks.push(createBlock(j, i, owned))
            }
        }
    }

    let filteredBlock: any[] = []

    for (let block of completedBlocks) {
        if (allOneColor(block) && noOverlappingBlocks(block, filteredBlock)) {
            filteredBlock.push(block)
        }
    }

    for (let block of completedBlocks) {
        if (noOverlappingBlocks(block, filteredBlock)) {
            filteredBlock.push(block)
        }
    }

    return filteredBlock
}

const isCompletedBlock = (x: number, y: number, owned: Location[]): boolean => {

    return owned.findIndex((location) => location.x === x && location.y === y) >= 0 &&
        owned.findIndex((location) => location.x === x + 1 && location.y === y) >= 0 &&
        owned.findIndex((location) => location.x === x && location.y === y + 1) >= 0 &&
        owned.findIndex((location) => location.x === x+ 1 && location.y === y + 1) >= 0
}

const noOverlappingBlocks = (block: Location[], completedBlocks: any[]) => {
    for (let completedBlock of completedBlocks) {
        for (let location of completedBlock) {
            if (block.findIndex((l) => l.x === location.x && l.y === location.y) >= 0) {
                return false
            }
        }
    }

    return true
}

const createBlock = (x: number, y: number, owned: Location[]): Location[] => {
    let block: Location[] = []

    for (let i = y; i < y + 2; i++) {
        for (let j = x; j < x + 2; j++) {
            block.push({ x: j, y: i, color: getColorForCell(j, i, owned)})
        }
    }

    return block
}

const getColorForCell = (x: number, y: number, owned: Location[]) => {
    let index : number = owned.findIndex((l) => l.x === x && l.y === y)

    return owned[index].color
}

const allOneColor = (block: Location[]) : boolean => {
    let colorSet = new Set(block.map((location) => location.color))

    return colorSet.size === 1
  }