import { Piece, Location } from "../../lib/pieces"
import { Row } from './Row'
import { Alert } from "../alerts/Alert"
import { useState } from "react"
import { GRID_SIZE } from "../../constants/settings"

type Props = {
  piece: Piece,
  dead: Location[],
  owned: Location[],
  nextDeadCell: Location,
  place: (location: Location) => void,
  hover: (location: Location) => void,
}

const extractRowPieces = (row: number, locations: Location[]) => {
  let rowPieces: Location[] = []

  for (let location of locations) {
    if (location.y === row) {
      rowPieces.push(location)
    }
  }

  return rowPieces
}

const isValidPlacement = (selectedPiece: Piece, owned: Location[], dead: Location[]) => {
  for (let location of owned) {
    for (let pointB of selectedPiece.dimensions) {
      let coordinate : Location = { x: pointB.x + selectedPiece.origin.x, y: pointB.y + selectedPiece.origin.y, color: 'None' }

      if ((location.y === coordinate.y && location.x === coordinate.x) || 
        (coordinate.x < 0 || coordinate.x >= GRID_SIZE || coordinate.y < 0 || coordinate.y >= GRID_SIZE)) {
        return false
      }
    }
  }

  for (let location of dead) {
    for (let pointB of selectedPiece.dimensions) {
      let coordinate : Location = { x: pointB.x + selectedPiece.origin.x, y: pointB.y + selectedPiece.origin.y, color: 'None' }

      if (location && location.y === coordinate.y && location.x === coordinate.x) {
        return false
      }
    }
  }

  return true
}

export const Grid = ({ piece, dead, owned, nextDeadCell, place, hover }: Props) => {

  const [alertMessage, setAlertMessage] = useState("")
  const [showAlert, setShowAlert] = useState(false)

  const handleCellHover = (row: number, column: number) => {
    hover({ x: column, y: row, color: piece.color })
  }

  const handleCellClick = (row: number, column: number) => {
    if (isValidPlacement(piece, owned, dead)) {
      place({ x: column, y: row, color: piece.color })
    } else {
      setAlertMessage("Illegal Block Placement")
      setShowAlert(true)
      setTimeout(() => {
        setAlertMessage("")
        setShowAlert(false)
      }, 500);
    }
  }

  return (
    <>
      <div className="py-2">
        {Array.from(Array(GRID_SIZE)).map((_, i) => (
          <Row 
            key={i} 
            row={i} 
            selectedPiece={piece} 
            owned={extractRowPieces(i, owned)} 
            dead={dead}
            nextDeadCell={nextDeadCell}
            hover={handleCellHover} 
            onClick={handleCellClick} />
        ))}
      </div>
      <Alert isOpen={showAlert} message={alertMessage} />
    </>
  )
}
