import Countdown from 'react-countdown'
import { shareStatus } from '../../lib/share'
import { tomorrow, getDayId } from '../../lib/seeds'
import { BaseModal } from './BaseModal'
import { Grid } from "../grid/Grid"
import { Location, DEFAULT_PIECE } from "../../lib/pieces"
import {
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  points: number
  colorGrid: string[][]
  ownedHistory: Location[],
  isOpen: boolean
  handleClose: () => void
  handleShare: () => void
}

export const GameOverModal = ({
  points,
  colorGrid,
  ownedHistory,
  isOpen,
  handleClose,
  handleShare,
}: Props) => {

    return (
        <BaseModal
            title={"Block Challenge #" + getDayId()}
            isOpen={isOpen}
            handleClose={handleClose}
        >
            <h4 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 my-4">
              {"Score " + points}
            </h4>
            <p className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
              {"Unique Grid History"}
            </p>
            <Grid 
              piece={{...DEFAULT_PIECE, origin: { x: -1, y: -1, color: 'None'}}} 
              dead={[]} 
              owned={ownedHistory} 
              nextDeadCell={{x: -1, y: -1, color: 'None'}}  
              place={() => null}
              hover={() => null}
            />
            <div className='flex justify-center'>
              <h5 className='text-gray-900 dark:text-gray-100 mx-2 text-2xl'>{NEW_WORD_TEXT}</h5>
              <Countdown
                className="text-2xl font-medium text-gray-900 dark:text-gray-100"
                date={tomorrow()}
                daysInHours={true}
              />
            </div>
            <button
              type="button"
              className="mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                shareStatus(getDayId().toString(), points, colorGrid)
                handleShare()
              }}
            >
              {SHARE_TEXT}
            </button>
        </BaseModal>
    )
}
