import { GRID_SIZE } from "../../constants/settings"
import { Piece, Location } from "../../lib/pieces"
import { CellStatus } from "./Cell"
import { Cell } from './Cell'

type Props = {
  row: number,
  selectedPiece: Piece,
  dead: Location[],
  owned: Location[],
  nextDeadCell: Location,
  hover: (row: number, column: number) => void,
  onClick: (row: number, column: number) => void,
}

const extractCellStatus = (
    row: number, 
    column: number, 
    selectedPiece: Piece, 
    owned: Location[], 
    deadCells: Location[], 
    nextDeadCell: Location): CellStatus => {
    
    let dying = isDying(row, column, nextDeadCell)

    for (let location of deadCells) {
        if (location.y === row && location.x === column) {
            return { status: 'Dead', color: 'slate', dying: dying }
        }
    }

    for (let point of selectedPiece.dimensions) {
      let coordinate : Location = { x: point.x + selectedPiece.origin.x, y: point.y + selectedPiece.origin.y, color: 'None' }

      for (let location of owned) {
        if (coordinate.y === row && coordinate.x === column && coordinate.x === location.x && coordinate.y === location.y) {
          return { status: 'Hover', color: 'red', dying: dying}
        }
      }

      if (coordinate.y === row && coordinate.x === column) {
        return { status: 'Hover', color: selectedPiece.color, dying: dying}
      }
  }

    for (let location of owned) {
      if (location.y === row && location.x === column) {
          return { status: 'Owned', color: location.color, dying: dying }
      }
    }

    return { status: 'None', color: 'None', dying: dying }
}

const isDying = (row: number, column: number, nextDeadCell: Location) => {

    return nextDeadCell && nextDeadCell.y === row && nextDeadCell.x === column
}

export const Row = ({ row, selectedPiece, owned, dead, nextDeadCell, hover, onClick }: Props ) => {
  const emptyCells = Array.from(Array(GRID_SIZE))

  return (
    <div className="flex justify-center mb-1">
      {emptyCells.map((_, i) => (
        <Cell 
            key={i} 
            column={i}
            status={extractCellStatus(row, i, selectedPiece, owned, dead, nextDeadCell)}
            hover={(column: number) => hover(row, column)} 
            onClick={(column: number) => onClick(row, column)} 
        />
      ))}
    </div>
  )
}
