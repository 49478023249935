import { GRID_SIZE } from '../constants/settings'
import { GAME_TITLE } from '../constants/strings'

export const shareStatus = (id: string, points: number, colorGrid: string[][]) => {
  navigator.clipboard.writeText(
    `${GAME_TITLE} #${id}\nScore : ${points}\n\n` +
      generateEmojiGrid(colorGrid)
  )
}

export const generateEmojiGrid = (colorGrid: string[][]) => {
  let emojiGrid = ""

  for (let i = 0; i < GRID_SIZE; i++) {
    for (let j = 0; j < GRID_SIZE; j++) {
      emojiGrid = emojiGrid + getColor(colorGrid[i][j])
    }
    emojiGrid = emojiGrid + "\n"
  }

  return emojiGrid
}

const getColor = (color: string) => {
  if (color === 'blue') {
    return '🟦'
  } else if (color === 'green') {
    return '🟩'
  } else if (color === 'orange') {
    return '🟧'
  } else if (color === 'yellow') {
    return '🟨'
  } else if (color === 'purple') {
    return '🟪'
  }

  return '⬜'
}
