import { useState, useEffect } from 'react'
import { Piece, Location, generateDeadCellOrder, getCompletedBlocks, generatePieceOrder} from './lib/pieces'
import { Banner } from "./components/banner/Banner"
import { Game } from './Game'
import { AboutModal } from './components/modals/AboutModal'
import { GameOverModal } from "./components/modals/GameOverModal"

import './App.css'
import { GRID_SIZE } from './constants/settings'

const generateColorGrid = () => {
  let colorGrid = []
  let colorRow = []

  for (let i = 0; i < GRID_SIZE; i++) {
    for (let j = 0; j < GRID_SIZE; j++) {
      colorRow.push('None')
    }
    colorGrid.push(colorRow)
    colorRow = []
  }

  return colorGrid
}

function App() {
  const [deadCellOrder] = useState(generateDeadCellOrder())
  const [pieceOrder] = useState(generatePieceOrder())
  const [deadCellIndex, setDeadCellIndex] = useState(0)
  const [pieceIndex, setPieceIndex] = useState(0)

  const [started, setStarted] = useState(false)
  const [points, setPoints] = useState(0)
  const [owned, setOwned] = useState<Location[]>([])
  const [colorGrid, setColorGrid] = useState<string[][]>(generateColorGrid())
  const [ownedHistory, setOwnedHistory] = useState<Location[]>([])
  const [dead, setDead] = useState<Location[]>([])
  const [nextDeadCell, setNextDeadCell] = useState<Location>({ x: 0, y: 0, color: 'None'})
  const [gameIsDone, setGameIsDone] = useState(false)

  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isGameOverModalOpen, setIsGameOverModalOpen] = useState(false)

  const [playSounds, setPlaySounds] = useState(false);
  const [winSound] = useState(new Audio('win.wav'))

  const update = () => {
    if (!started) return

    if (!gameIsDone && deadCellIndex < deadCellOrder.length) {
      setDeadCellIndex((prevIndex) => prevIndex + 1)
    } else {
      console.log("End Game")
      handleFinish()
    }
  }

  useEffect(() => {
    if (gameIsDone || deadCellIndex >= deadCellOrder.length || isAboutModalOpen) return

    let newDeadCell = deadCellOrder[deadCellIndex]
    setNextDeadCell(deadCellOrder[Math.min(deadCellOrder.length, deadCellIndex + 1)])

    if (started) {
      setDead((prevState) => [...prevState, newDeadCell])
    }

    if (deadCellIndex + 1 === deadCellOrder.length -1) {
      console.log("End Game")
      handleFinish()
    }
  }, [deadCellIndex]);

  useEffect(() => {
    if (gameIsDone || deadCellIndex >= deadCellOrder.length || isAboutModalOpen) return

    const interval = setInterval(() => {
      update()
    }, 3800);
    return () => clearInterval(interval);
  }, [started]);

  const place = (piece: Piece) => {
    if (!started) return

    const newLocations: Location[] = extractLocations(piece)
    setOwnedHistory([...ownedHistory, ...newLocations])
    setColorGrid(updateColorGrid(newLocations))

    setTimeout(() => {
      const completedBlocks = getCompletedBlocks([...owned, ...newLocations])
      setPoints(points + calculatePoints(completedBlocks))
      setOwned(removeCompletedBlocks([...owned, ...newLocations], completedBlocks, dead))
    }, 100);
  }

  const updateColorGrid = (locations: Location[]) => {
    let newColorGrid = [...colorGrid]

    for (let location of locations) {
      newColorGrid[location.y][location.x] = location.color
    }

    return newColorGrid
  }

  const calculatePoints = (completedBlocks: any[]) => {
    let pointSum = 0

    for (let block of completedBlocks) {
      pointSum += allOneColor(block) ? 8 : 4
    }

    if (pointSum > 0) {
      playSounds && winSound.play()
    }

    return pointSum * completedBlocks.length
  }

  const allOneColor = (block: Location[]) : boolean => {
    let colorSet = new Set(block.map((location) => location.color))

    return colorSet.size === 1
  }

  const removeCompletedBlocks = (current: Location[], completedBlocks: any[], deadCells: Location[]) => {
    let newCurrent = [ ...current ]

    for (let block of completedBlocks) {
      for (let location of block) {
        newCurrent = newCurrent.filter((l) => !(l.x === location.x && l.y === location.y))
      }
    }

    for (let location of deadCells) {
      newCurrent = newCurrent.filter((l) => !(l.x === location.x && l.y === location.y))
    }

    return newCurrent
  }
  
  const extractLocations = (piece: Piece) => {
    let locations: Location[] = []

    for (let point of piece.dimensions) {
      locations.push({ x: piece.origin.x + point.x, y: piece.origin.y + point.y, color: piece.color })
    }

    return locations
  }

  const handleFinish = () => {
    setNextDeadCell(deadCellOrder[1])
    setGameIsDone(true)
    setIsGameOverModalOpen(true)
  }

  const replay = () => {
    setDead([])
    setColorGrid(generateColorGrid())
    setOwnedHistory([])
    setOwned([])
    setPoints(0)

    setTimeout(() => {
      setDeadCellIndex(0)
      setPieceIndex(0)
      setGameIsDone(false)
    }, 200)
  }

  const toggleAudio = () => {
    setPlaySounds(!playSounds)
  }

  if (!started) {
    return (
      <div className="py-6 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <Banner points={points} audioOn={playSounds} toggleAudio={toggleAudio} />
        <AboutModal 
          isOpen={isAboutModalOpen}
          handleClose={() => setIsAboutModalOpen(false)}
        />
        <button
          type="button"
          className="mx-auto mt-24 mb-10 px-16 py-4 flex text-4xl items-center text-2xl border border-transparent font-bold rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
          onClick={() => setStarted(true)}
        >
          {"Start"}
        </button>
        <button
              type="button"
              className="mx-auto flex px-3 items-center text-2xl border border-transparent font-bold rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
              onClick={() => setIsAboutModalOpen(true)}
          >
              {"About Game"}
        </button>
      </div>
    )
  }

  return (
    <div className="py-6 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <Banner points={points} audioOn={playSounds} toggleAudio={toggleAudio}  />
      <Game 
        owned={owned} 
        dead={dead} 
        pieceOrder={pieceOrder} 
        pieceIndex={pieceIndex} 
        nextDeadCell={nextDeadCell} 
        place={place} 
        increment={() => setPieceIndex((prevState) => prevState + 1)} />
      <AboutModal 
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />
      <GameOverModal 
        points={points}
        colorGrid={colorGrid}
        ownedHistory={ownedHistory}
        isOpen={isGameOverModalOpen}
        handleClose={() => setIsGameOverModalOpen(false)}
        handleShare={() => null}
      />
      <button
            type="button"
            className="mx-auto mb-4 px-12 flex items-center text-2xl border border-transparent font-bold rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={() => gameIsDone ?  replay() : handleFinish()}
        >
            {gameIsDone ? "Replay" : "Finish"}
      </button>
      <button
            type="button"
            className="mx-auto flex px-3 items-center text-2xl border border-transparent font-bold rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
            onClick={() => setIsAboutModalOpen(true)}
        >
            {"About Game"}
      </button>

    </div>
   )
}

export default App
