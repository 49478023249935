import { Piece, Location } from "../../lib/pieces"
import { Cell } from './Cell'
import { CellStatus } from "./Cell"

type Props = {
  piece: Piece,
  nextPiece: Piece
}

const getCellStatus = (row: number, column: number, piece: Piece) => {
    for (let point of piece.dimensions) {
        if (row === point.y && column === point.x) {
            return { status: 'Owned', color: piece.color, dying: false }
        }
    }

    return { status: 'None', color: piece.color, dying: false }
}

export const BlockPreview = ({ piece, nextPiece }: Props) => {

  return (
    <div className="flex justify-center">
        <div className="mx-4 scale-75">
            <div className="flex justify-center mb-1 items-center mx-0.5 text-3xl font-bold rounded dark:text-white">
                {"Current"}
            </div>
            <div className="flex justify-center mb-1">
                <Cell column={0} key={0} status={getCellStatus(-1, -1, piece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={1} status={getCellStatus(-1, 0, piece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={2} status={getCellStatus(-1, 1, piece)} hover={() => null} onClick={() => null} />
            </div>
            <div className="flex justify-center mb-1">
                <Cell column={0} key={3} status={getCellStatus(0, -1, piece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={4} status={getCellStatus(0, 0, piece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={5} status={getCellStatus(0, 1, piece)} hover={() => null} onClick={() => null} />
            </div>
            <div className="flex justify-center mb-1">
                <Cell column={0} key={6} status={getCellStatus(1, -1, piece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={7} status={getCellStatus(1, 0, piece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={8} status={getCellStatus(1, 1, piece)} hover={() => null} onClick={() => null} />
            </div>
        </div>
        <div className="mx-4 scale-75">
            <div className="flex justify-center mb-1 items-center mx-0.5 text-3xl font-bold rounded dark:text-white">
                {"Next"}
            </div>
            <div className="flex justify-center mb-1">
                <Cell column={0} key={9} status={getCellStatus(-1, -1, nextPiece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={10} status={getCellStatus(-1, 0, nextPiece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={11} status={getCellStatus(-1, 1, nextPiece)} hover={() => null} onClick={() => null} />
            </div>
            <div className="flex justify-center mb-1">
                <Cell column={0} key={12} status={getCellStatus(0, -1, nextPiece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={13} status={getCellStatus(0, 0, nextPiece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={14} status={getCellStatus(0, 1, nextPiece)} hover={() => null} onClick={() => null} />
            </div>
            <div className="flex justify-center mb-1">
                <Cell column={0} key={15} status={getCellStatus(1, -1, nextPiece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={16} status={getCellStatus(1, 0, nextPiece)} hover={() => null} onClick={() => null} />
                <Cell column={1} key={17} status={getCellStatus(1, 1, nextPiece)} hover={() => null} onClick={() => null} />
            </div>
        </div>
    </div>
  )
}
