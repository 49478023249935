import { BaseModal } from './BaseModal'
import { StripeDonation } from '../donate/StripeDonation'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {

  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-gray-500 dark:text-gray-300 mb-6">
        Block Placing Game
      </p>
      <div className="mb-5">
        <StripeDonation />
      </div>
      <p className="text-gray-500 dark:text-gray-300 mb-2">
        Written by Jason McDonald
      </p>
      <p className="text-gray-500 dark:text-gray-300">
        jason.mcdonald11@gmail.com
      </p>
    </BaseModal>
  )
}
