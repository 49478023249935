import classnames from 'classnames'
import { useState } from 'react'

export type CellStatus = {
  status: string,
  color: string,
  dying: boolean
}

type Props = {
  column: number
  id?: number,
  status?: CellStatus,
  hover: (column: number) => void
  onClick: (column: number) => void
}

export const Cell = ({ column, id, status, hover, onClick }: Props) => {
  const [hovering, setHovering] = useState(false)

  const classes = classnames(
    'w-10 h-10 border-solid border-2 border-slate-500 flex items-center justify-center mx-0.5 font-bold rounded dark:text-white', {
      'border-4 border-slate-300': hovering,
      'border-4 border-red-500': status?.dying,
      'cell-animation': !!id,
      'bg-sky-500': status?.color === 'blue' && status?.status === 'Owned',
      'bg-sky-300': status?.color === 'blue' && status?.status === 'Hover',
      'bg-sky-500 border-4 border-red-500': status?.color === 'blue' && status?.status === 'Owned' && status?.dying,
      'bg-sky-300 border-4 border-red-500': status?.color === 'blue' && status?.status === 'Hover' && status?.dying,
      'bg-lime-500': status?.color === 'green' && status?.status === 'Owned',
      'bg-lime-300': status?.color === 'green' && status?.status === 'Hover',
      'bg-lime-500 border-4 border-red-500': status?.color === 'green' && status?.status === 'Owned' && status?.dying,
      'bg-lime-300 border-4 border-red-500': status?.color === 'green' && status?.status === 'Hover' && status?.dying,
      'bg-orange-500': status?.color === 'orange' && status?.status === 'Owned',
      'bg-orange-300': status?.color === 'orange' && status?.status === 'Hover',
      'bg-orange-500 border-4 border-red-500': status?.color === 'orange' && status?.status === 'Owned' && status?.dying,
      'bg-orange-300 border-4 border-red-500': status?.color === 'orange' && status?.status === 'Hover' && status?.dying,
      'bg-yellow-500': status?.color === 'yellow' && status?.status === 'Owned',
      'bg-yellow-300': status?.color === 'yellow' && status?.status === 'Hover',
      'bg-yellow-500 border-4 border-red-500': status?.color === 'yellow' && status?.status === 'Owned' && status?.dying,
      'bg-yellow-300 border-4 border-red-500': status?.color === 'yellow' && status?.status === 'Hover' && status?.dying,
      'bg-violet-500': status?.color === 'purple' && status?.status === 'Owned',
      'bg-violet-300': status?.color === 'purple' && status?.status === 'Hover',
      'bg-violet-500 border-4 border-red-500': status?.color === 'purple' && status?.status === 'Owned' && status?.dying,
      'bg-violet-300 border-4 border-red-500': status?.color === 'purple' && status?.status === 'Hover' && status?.dying,
      'bg-red-500': status?.color === 'red',
      'bg-slate-700': status?.status === 'Dead',

    }
  )

  const handleHover = () => {
    setHovering(true)
    hover(column)
  }

  return (
    <button className={classes} onMouseEnter={handleHover} onMouseLeave={() => setHovering(false)} onClick={() => onClick(column)}>

    </button>
  )
}
